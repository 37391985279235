@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.sidebar {
  @include flex(column);
  $root: &;
  min-width: 350px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 100vh;
  max-height: 100vh;

  position: sticky;
  top: 0;

  &__logo {
    @include flex(row, center, center);
    width: 100%;
    margin: 20px 0;

    #{$root}__ncoded-img {
      @include size(200px, 55px);

      display: flex;
    }
  }

  &__welcome {
    @include flex(row, center);
    width: 100%;
    $welcome: &;
    margin-bottom: 20px;
    padding-top: 2rem;

    &__text {
      @include fonts(16px, 400, normal, normal);

      color: $color-gray-4f;

      #{$welcome}__role {
        @include fonts(16px, 600, normal, normal);

        color: $color-gray-33;
      }
    }
  }

  &__user {
    @include flex(column, center, center, 1rem);
    width: 100%;
    margin-bottom: 26px;
    #{$root}__image-loader {
      @include size(120px);
      border-radius: 120px;
      border: 2px solid $color-black;
      margin-bottom: 20px;
    }
    #{$root}__loader {
      display: none;
      &--active {
        display: flex;
        @include size(120px);
        margin-bottom: 20px;
        object-fit: cover;
        background-color: $background-primary;
        align-self: center;
      }
    }

    &__name {
      @include flex(row, flex-start, center);
      gap: 0.5em;

      #{$root}__book {
        @include size(20.1px, 20px);

        > g > rect {
          fill: $color-gray-26;
        }
      }

      #{$root}__firstname,
      #{$root}__lastname {
        @include fonts(20px, 700, normal, normal);

        color: $color-gray-26;
        text-align: center;
      }
    }
  }

  &__open-logout {
    @include flex(row);
    width: 100%;
    #{$root}__logout-img {
      @include flex(row, center);
      @include size(20px);
    }

    #{$root}__button-open {
      @include flex(row, flex-start, center, 1em);
      @include fonts(16px, 400, normal, normal);

      background-color: $color-white;
      color: $color-gray-4f;
      padding: 15px 38px;
      height: auto;
      width: 100%;

      border-radius: 0;

      > svg > g > rect {
        fill: $color-gray-4f;
      }

      &:hover {
        box-shadow: none;
        background-color: $color-gray-eb;
      }
    }
  }

  &__nav {
    @include flex(row, center, center);
    width: 100%;
    height: 3rem;
    position: relative;

    &__img-wrapper {
      flex-grow: 1;
      text-align: center; /* Center the image horizontally */
    }

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1.5rem;
      margin-right: 1rem;
    }

    &__ncoded-img {
      @include size(12rem, 3rem);
      max-width: 100%;
    }
  }
}

.header-sidebar {
  @include flex(row, center, center);
  width: 100%;
  height: 3rem;
  position: relative;

  &__img-wrapper {
    flex-grow: 1;
    text-align: center; /* Center the image horizontally */
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1.5rem;
    margin-right: 1rem;
  }

  &__ncoded-img {
    @include size(12rem, 3rem);
    max-width: 100%;
  }
}

@include sm-breakpoint {
  .panel {
    flex-direction: column;
  }

  .sidebar {
    height: 100vh;
    position: fixed;
    z-index: 3;
    background-color: $color-white;
    width: 100%;
  }

  .header-sidebar {
    position: fixed;
    z-index: 3;
    background-color: $color-white;
    cursor: pointer;
  }
}
