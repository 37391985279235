@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.nce-employee-document-actions {
  width: 100%;
  @include flex(row, center, center);

  a {
    display: none;
  }

  button {
    padding: 6px;

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      outline: none;
    }
  }

  .document-remove-button {
    padding-left: 0;
  }

  button,
  svg,
  path {
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      outline: none;
    }
  }

  .document-visibility-loading {
    @include size(12px);

    margin: 0 2px;
    margin-top: 4px;
  }

  .action-download,
  .action-hide,
  .action-show,
  .action-edit,
  .action-remove {
    @include size(20px);
    color: $color-gray-4f;
    fill: $color-gray-4f;
    cursor: pointer;
    &:hover {
      fill: $royal-peacock;
      color: $royal-peacock;
      transition: 0.2s;
    }
  }

  .action-show,
  .action-hide {
    fill: none;
    stroke: $color-gray-4f;
    &:hover {
      stroke: $royal-peacock;
      fill: none;
    }
  }

  .action-remove {
    fill: $color-red;

    &:hover {
      opacity: 50%;
      fill: $color-red;
    }
  }
}
