@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/colors.scss";
@import "src/sass/breakpoints.module.scss";

.ne-timeoffs {
  &__icon {
    @include size(1.5rem);
    fill: $color-white;
  }

  &__status-button {
    &:hover {
      box-shadow: 0px 0px 10px 0px $color-gray;
    }
  }

  &__page-header {
    @include flex(row, space-between, center);
  }

  @include lg-breakpoint {
    &__page-header {
      @include flex(column, flex-start, flex-start, 1rem);
    }
    gap: 1rem;
  }

  @include sm-breakpoint {
    &__icon {
      width: 0px;
    }
    &__status-button {
      svg {
        visibility: hidden;
        width: 0px;
      }
    }
  }

  @include sm-breakpoint {
    padding-top: 5rem;

    .ne-section-block {
      gap: 1rem;
    }

    &__page-header {
      @include flex(row, space-between, center);
    }

    &__status-button {
      font-size: 12px;
    }
  }

  @include xs-breakpoint {
    &__icon {
      width: 1.5rem;
    }
    &__status-button {
      font-size: 0px;
      gap: 0;
      svg {
        visibility: visible;
        width: 1.5rem;
      }
    }
  }

  &__employee {
    @include flex($align-items: center, $gap: 12px);
  }
}
