@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.ne-select {
  @include flex(column, center);

  select {
    @include size(100%, 40px);
    border: none;
    border-bottom: 2px solid $color-gray-33;
    @include fonts(16px);
  }

  label {
    @include fonts(14px, 600);
    color: $color-gray-4f;
  }
}
