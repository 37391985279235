@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/animation/fade-in.module.scss';
@import 'src/sass/breakpoints.module.scss';

.modal {
  @include size(100%, 100%);
  @include fade-in(0.5s);

  position: fixed;
  top: 0;
  z-index: 1000;

  @include flex(column, center, center);

  &__container {
    filter: drop-shadow(2px 2px 28px rgba(28, 34, 79, 0.25));

    position: relative;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.3);
    @include size(100%, 100%);
    position: absolute;
    z-index: -1;
  }

  &__content__close {
    @include size(22px);

    position: absolute;
    top: 8px;
    right: 8px;

    &:hover {
      cursor: pointer;
      fill: rgba(85, 85, 85, 1);
      border-radius: 50%;
      transition: all 0.15s;
    }
  }

  &__content {
    @include flex(column, center);
    border-radius: 5px;
    background-color: $color-white;
    // overflow: auto;

    max-width: calc(100% - 4rem);
    max-height: calc(100% - 4rem);
    min-width: 30rem;
    margin: 1rem;
    box-sizing: border-box;
    position: relative;
    padding: 2rem;
  }

  @include sm-breakpoint {
    &__content {
      min-width: 20rem;
      .vacation-request {
        width: 100%;
        h1 {
          @include flex(column);
          font-size: 16px;
        }
        &__content {
          min-width: 14rem;
          padding: 1rem;
        }
        .dropzone-container__wrapper {
          width: 16rem;
        }
      }
    }
  }
}
