@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.ne-remove-document-modal {
  &__content {
    @include flex(column, flex-start, center);
    gap: 2rem;
    text-align: center;
  }
}
