@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/flex.module.scss";

.salary-display {
    @include flex(column, flex-start, flex-start);

    &__primary {
        @include fonts(16px, 20px, 500);
    }

    &__secondary {
        @include fonts(12px, 16px, 500);

        color: $color-gray-4f;
    }
}
