@import "src/sass/breakpoints.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/colors.scss";

.tabs-v2-container {
  @include flex(column, stretch, flex-start);

  .tabs-v2 {
    @include flex(row, space-around, center);
    background-color: $color-white;

    &__button:hover {
      color: $color-gray-82;
    }

    &__button {
      background-color: $color-white;
      color: $color-black;
      font-weight: 400;

      &--submitted {
        color: $royal-orange;
        &:hover {
          color: $supernatural-saffron;
        }
      }

      &--fulfilled {
        color: $industrial-turquoise;
        :hover {
          color: $mountain-meadow;
        }
      }

      &--pending {
        color: $royal-peacock;
        :hover {
          color: $mountain-meadow;
        }
      }
    }

    &__tab-line {
      position: absolute;
      align-self: flex-end;
      background-color: $royal-peacock;
      height: 2px;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      transition-duration: 300ms;
    }
  }

  &__dropdown-button {
    visibility: hidden;
    height: 0px;
    min-height: 0px;
    padding: 0;
  }

  @include sm-breakpoint {
    &__dropdown-button {
      visibility: visible;
      width: 100%;
      min-height: 2rem;
      text-align: center;
      @include flex(row, space-between, center);
      margin-bottom: 0.5rem;
      border: 1px solid $color-gray-d9;
      background-color: $color-gray-d9;
      padding: 0.5rem;

      svg {
        fill: $color-black;
        transform: rotate(-90deg);
      }

      &--submitted {
        border: 1px solid $royal-orange;
        color: $royal-orange;
      }

      &--fulfilled {
        border: 1px solid $industrial-turquoise;
        color: $industrial-turquoise;
      }
    }
    .tabs-v2 {
      @include flex(column, flex-start, center);
      border: 1px solid $color-gray-e0;
      border-radius: 0.5rem;
      &__tab-line {
        visibility: hidden;
      }
      &__button {
        width: 100%;
        &:hover,
        &:active {
          background-color: $color-gray-eb;
        }
      }
    }

    .non-dropdown {
      visibility: hidden;
      height: 0px;
    }

    .dropdown {
      width: 100%;
    }
  }
}
