@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.edit-nwd-form {
  ul {
    list-style-type: none;
    @include flex(row);
    flex-wrap: wrap;
    gap: 1rem;

    li {
      background-color: $color-white;
      border: 1px solid $color-black;

      p {
        @include fonts(16px, 500);
        color: $color-gray-4f;
        font-weight: 600;
        text-align: center;
      }

      b {
        @include fonts(32px, 600);
        color: $color-gray-4f;
        text-align: center;
      }
    }
    @include xs-breakpoint {
      gap: 0.5rem;
      li {
        b {
          @include fonts(28px, 600);
        }
        p {
          @include fonts(12px, 500);
        }
      }
    }
  }

  &__total {
    @include flex(column, $gap: 4px);
    font-size: 14px;

    &__year {
      @include flex(row, center, center, 0.5rem);
    }

    &__days {
      font-weight: bold;
    }

    label {
      min-width: 90px;
    }
  }

  &__remaining {
    @include flex(column, $gap: 6px);

    font-family: "Poppins";
    font-size: 14px;
    &__year {
      @include flex(column, center, center, 6px);
      @include fonts(14px, 500);
      color: $color-gray-4f;
      font-weight: 600;
      text-align: center;
    }

    &__days {
      font-weight: bold;
    }
  }

  &__progress-circle {
    @include flex(row, center, center);
  }

  .ne-input {
    @include flex(column, flex-start, center);
    label {
      text-align: center;
    }

    &--disabled {
      div {
        border-bottom: 2px solid $color-gray-d9;
      }
    }
  }

  input {
    text-align: center;
    width: 44px;
    height: unset;
    padding-left: 12px;
  }

  &__actions-wrapper {
    @include flex(row, center, center, 16px);
    button {
      align-self: center;
      flex: 1;
      width: 200px;
    }
  }

  &--modal {
    @include flex(column);
    gap: 2rem;

    ul {
      li {
        flex: 1;
        @include flex(column, flex-start, center, 1rem);
        padding: 1rem;
        border-radius: 1rem;
        width: 130px;
        border-radius: 1rem;
        min-width: 10rem;
      }
    }
  }

  &--standard {
    @include flex(column, $gap: 32px);
    background-color: $color-white;
    border-radius: 8px;
    margin-top: 1.5rem;
    padding: 32px;

    h1 {
      margin: 0;
      font-size: 1.5rem;
      div {
        @include flex(row, space-between, center);
        p {
          @include flex(row, flex-start, center, 8px);
          button {
            padding: 0;
          }
        }
        div {
          svg {
            @include size(20px);
            &:hover {
              fill: $royal-peacock;
            }
          }
          .ncoded-employee-button--disabled {
            svg {
              &:hover {
                fill: $color-gray-d9;
              }
            }
          }
        }

        .employee-non-working-days__open {
          &:hover {
            cursor: pointer;
          }
          button {
            svg {
              transform: rotate(270deg);
              transition-duration: 0.5s;
            }
          }
        }
        .employee-non-working-days__closed {
          &:hover {
            cursor: pointer;
          }
          button {
            svg {
              transform: rotate(180deg);
              transition-duration: 0.5s;
            }
          }
        }
      }
    }

    ul {
      @include flex(row, center, stretch, 16px);

      li {
        @include flex(column, $gap: 16px);
        padding: 1rem;
        border-radius: 16px;
        width: 158px;
      }
    }
  }
}
