@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';

.new-employee-form {
  form {
    @include flex(column);
    gap: 4rem;
    h1,
    h2 {
      text-align: center;
    }
    button[type='submit'] {
      align-self: center;
    }
    h2 {
      @include fonts(14px, 700, normal, 21px);
      color: $color-gray-bd;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 320px));
    align-items: baseline;
    gap: 1rem;

    &-image {
      .label {
        @include flex(column);
        flex: 0.95;
        @include fonts(14px, 600, normal, 21px);
        color: $color-gray-4f;
      }
      p {
        @include fonts(12px, 400);
        color: $color-gray-bd;
        span {
          font-weight: 700;
          color: $color-gray-4f;
        }
      }
      @include flex(row, $align-items: center !important);

      .image-upload-container,
      .image-upload-container__image-wrapper,
      img {
        @include size(90px !important, 90px !important);
        &__remove-button {
          top: -10px;
          right: -20px;
        }
        .dropzone-container {
          &__button {
            min-height: unset;
            @include size(28px);
            padding: 5px;
            top: 82px;
          }
        }
      }
    }
  }
}
