@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.add-admin {
    &__form {
        @include flex(column, $gap: 16px);

        h2 {
            text-align: center;
        }
    }
}
