@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';

.year-picker {
  @include flex(row, center, center);
  gap: 1rem;

  b {
    width: 64px;
    text-align: center;
  }

  .ncoded-employee-button {
    background-color: $color-white;
    color: $color-black;
    padding: 4px 8px;
    min-height: auto;
    @include size(2rem);

    &:hover:not(:disabled),
    &.selected:not(:disabled) {
      background-color: $color-black;
      color: $color-white;
    }
  }
  .arrow-left,
  .arrow-right {
    &:hover:not(:disabled) {
      svg {
        path {
          stroke: $color-white;
        }
      }
    }
    padding: 0;
  }

  .arrow-left {
    &--disabled {
      opacity: 50%;
    }
  }

  .arrow-right {
    scale: -1;
    &--disabled {
      scale: -1;
      opacity: 50%;
    }
  }
}
