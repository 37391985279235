@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.multiplier-form-modal {
  form {
    @include flex(column);
    gap: 2rem;

    button {
      align-self: center;
    }
  }

  .modal__content {
    max-width: 480px;
  }
}

.add-multiplier-button {
  &:hover {
    opacity: 80%;
    box-shadow: 0px 0px 10px 0px $color-gray;
  }

  @include xs-breakpoint {
    font-size: 0px;
    gap: 0;
  }
}
