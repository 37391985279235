@import "src/sass/colors.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";

.nce-employee-card {
  $root: &;
  @include size(216px, 280px);
  @include flex(column, flex-start, center, 15px);
  background-color: $color-white;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s;

  &--deleted-user {
    opacity: 0.7;
  }

  &__image {
    @include size(120px, 120px);
    margin-top: 20px;
    object-fit: cover;
    border-radius: 80%;
    img {
      @include size(120px);
      border-radius: 162px;
    }
  }

  &__info {
    @include flex(column, center, center);
    @include fonts(16px, 700, normal, 18px);

    #{$root}__firstName,
    #{$root}__lastName {
      @include fonts(22px, bold);
      color: $color-gray-4f;

      @include sm-breakpoint {
        @include fonts(18px, bold);
      }

      @include xs-breakpoint {
        @include fonts(15px, bold);
      }
    }
  }

  @include sm-breakpoint {
    @include size(150px, 200px);
    &__image {
      @include size(80px, 80px);
      img {
        @include size(80px, 80px);
      }
    }
  }

  @include xs-breakpoint {
    @include size(110px, 170px);
    &__image {
      @include size(60px, 60px);
      img {
        @include size(60px, 60px);
      }
    }
  }
}
.nce-employee-card:hover {
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.08);
}
