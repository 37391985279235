@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.download-file {
  background-color: transparent;
  border: none;

  &__icon {
    @include size(20px);
    fill: $color-gray-2f;
    cursor: pointer;
  }

  &:disabled {
    opacity: 20%;
  }

  &:hover:enabled {
    cursor: pointer;
    svg {
      fill: $royal-peacock;
      transition: 0.2s;
    }
  }
}
