@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/animation/fade-in.module.scss';
@import 'src/sass/colors.scss';

.password-reset {
  $root: &;

  height: 100vh;

  &__container {
    @include size(100%);
    @include flex(row, center, center);
    @include fade-in(1.5s);
    background-color: $background-primary;

    #{$root}__wrapper {
      @include flex(column, center, center, 8px);
      @include size(300px, 370px);

      padding: 50px 40px;
      position: relative;
      background-color: $color-white;
      border-radius: 10px;
      margin-bottom: 80px;

      #{$root}__header {
        margin-top: 85px;
        &__logo {
          @include size(65px);
          position: absolute;
          top: 25px;
          left: 50%;

          transform: translate3d(-50%, 0, 0);
        }
        &__title {
          @include fonts(25px, bold);
        }
      }

      #{$root}__body {
        &__form {
          @include flex(column, center, center);
          width: 100%;
          margin-top: 30px;
          margin-bottom: 25px;

          .password-field {
            margin: 8px 0;
          }

          &__input {
            width: 300px;
            label {
              @include fonts(15px);
            }
            margin-top: 0;
          }

          &__button {
            @include fonts(13px, bold);
            @include size(100%, 35px);
            margin-top: 42px;
          }
        }
      }
    }
  }
}
