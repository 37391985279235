@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.logout-modal {
  @include size(420px, 260px);
  @include flex(column);
  align-self: center;

  border-radius: 12px;
  background-color: $color-white;
  padding: 10px;

  &__title {
    @include fonts(14px, 700, normal, normal);
    margin-top: 18px;
    text-align: center;
    color: $color-gray-4f;
  }

  &__body {
    @include flex(row, center, center);
    @include fonts(16px, 500, normal, normal);

    flex: 50%;
    text-align: center;
    color: $color-gray-82;
  }
  &__footer {
    @include flex(row, center, center);

    &__button,
    &__button__cancel {
      @include size(100%, 45px);

      margin: 10px 10px 0;
      border: none;
      background-color: $color-gray-26;
      color: $color-white;
      border-radius: 8px;
      font-size: 20px;
      cursor: pointer;
      &__cancel {
        background-color: transparent;
        color: $color-gray-26;
        border: 1px solid $color-gray-82;
      }
    }

    &__button:hover {
      box-shadow: 0 3px 10px $color-gray-82;
      background-color: $color-gray-32;
      transition-duration: 0.3s;
    }

    &__button__cancel:hover {
      background-color: $color-gray-d9;
      transition-duration: 0.3s;
    }
  }

  @include sm-breakpoint {
    @include size(320px, 260px);
  }

  @include xs-breakpoint {
    @include size(220px, 260px);
    &__title {
      h1 {
        font-size: 20px;
      }
    }

    &__body {
      p {
        font-size: 14px;
      }
    }

    &__footer {
      button {
        font-size: 15px;
      }
    }
  }
}
