@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.admins-table {
  padding: 0;

  table {
    overflow-x: auto;
  }
  td:nth-child(n + 3),
  th:nth-child(n + 3) {
    text-align: center;
    p {
      text-align: center;
    }
  }

  td:nth-child(2),
  th:nth-child(2),
  td:nth-child(1),
  th:nth-child(1) {
    width: 30%;
  }
}
