@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.settings {
  @include flex(column, flex-start, normal, 20px);
  @include size(100%);
  min-height: 100vh;
  background-color: $background-secondary;
  padding: 15px;
  h1 {
    @include fonts(26px, 700, normal, 39px);
    margin-top: 5px;
    margin-bottom: 12px;
    color: $color-gray-4f;
  }
  &__salary-variables {
    @include flex(row, flex-start, normal, 10px);
  }
  &__tabs {
    @include flex(row, flex-start, normal);
    position: relative;
  }

  &__tab {
    @include size(178px, 51px);
    @include fonts(14px, 500, normal, 21px);
    background-color: $color-white;
    color: $color-gray-82;
    border-radius: 0px;
    border-bottom: solid 1px $color-gray-82;
    padding: 15px;
    box-shadow: none;
    background-color: $background-secondary;

    &:hover {
      box-shadow: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: linear-gradient(90deg, $color-gray-f5 0%, $color-gray-f8 35%, $color-gray-fb 100%);
    }

    &-chosen {
      color: $color-gray-4f;
      border-bottom: none;
      z-index: 1;
      box-shadow: none;
      background: linear-gradient(0deg, $background-secondary, $color-white 65%);
      border: solid $color-gray-82 1px;
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

@include sm-breakpoint {
  .ne-page {
    padding-top: 4rem;
  }
}
