@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';

.spinner {
  @include size(16px);

  border-radius: 50%;
  border: 2px solid $color-box-shadow;
  border-top: 2px solid $color-black;

  animation: spin 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
