$color-white: #fff;
$background-primary: #eeeeee;
$background-secondary: #f2f2f2f2;
$color-gray-dd: #dddddd;
$color-gray-e0: #e0e0e0;
$color-gray-e6: #e6e6e6;
$color-gray-eb: #ebebeb;
$color-gray-f1: #f1f1f1;
$color-gray-f2: #f2f2f2;
$color-gray-f5: #f5f5f5;
$color-gray-f8: #f8f8f8;
$color-gray-fb: #fbfbfb;
$color-gray-d9: #d9d9d9;
$color-gray-bd: #bdbdbd;
$color-gray-aa: #aaaaaa;
$color-gray: #808080;
$color-gray-82: #828282;
$color-gray-66: #666;
$color-gray-77: #777;
$color-gray-88: #888;
$color-gray-4f: #4f4f4f;
$color-gray-30: #303030;
$color-gray-33: #333;
$color-gray-32: #323639;
$color-gray-2f: #2f2f2f;
$color-gray-26: #262626;
$color-black: #000;

$color-red: red;
$color-orange: #ffa500;
$venetian-red: #eb5757;
$royal-orange: #f2994a;
$supernatural-saffron: #f3760f;
$royal-peacock: #29abe2;
$mountain-meadow: #00b897;
$industrial-turquoise: #008c73;

$input-color-default: rgba(128, 128, 128, 0.2);
$color-dropzone-background: rgba(224, 224, 224, 0.3);
$color-box-shadow: rgba(0, 0, 0, 0.25);
