@import 'src/sass/fonts.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';

.table-row-message {
  $b-root: &;

  font-style: italic;

  #{$b-root}__td:first-child {
    @include fonts(16px, 400);
    grid-column: 1/-1;
    border: none;
    text-overflow: initial;
  }

  &--error {
    p {
      color: $color-red;
    }
  }
  &--loading {
    #{$b-root}__td:first-child {
      @include flex($justify-content: center, $align-items: center);
    }
  }
}
