@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/size.module.scss';

.ne-invite-employee {
  &--secondary {
    .ncoded-employee-button {
      @include flex(column, flex-end, flex-end);
      @include size(100%);
      @include fonts(14px);

      min-height: auto;
      padding: 0;
      text-decoration: underline;
      color: $royal-peacock;
    }
  }

  &__modal {
    &__content {
      @include flex(column, flex-start, center);
      gap: 4rem;
      text-align: center;
    }
  }
}
