@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.ne-non-working-days {
  .nce-table {
    &__edit {
      .ncoded-employee-button {
        @include size(2.5rem);
        background-color: $color-white;
        color: $color-black;
        padding: 4px 8px;

        &:hover:not(:disabled) {
          svg {
            fill: $royal-peacock;
            transition: 0.2s;
          }
        }

        &:disabled {
          opacity: 20%;
        }
      }
    }

    &__employee,
    &__start-date {
      p {
        @include flex(row, flex-start, center, 0.5rem);
        button {
          width: 1.5rem;
          padding: 0;
        }
      }
    }

    td.nce-table__employee:hover {
      cursor: pointer;
    }
  }

  @include sm-breakpoint {
    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 0;
          }
        }
      }
    }
  }

  @include xs-breakpoint {
    h2 {
      font-size: 18px;
    }
    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 0;
          }
        }
      }
    }
  }
}

.employee-sort-icon--1,
.start-date-sort-icon--1 {
  svg {
    transform: scale(-1);
  }
}
