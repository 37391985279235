@import 'src/sass/flex.module.scss';

.table-with-year-picker {
  &__title {
    @include flex($justify-content: space-between, $align-items: flex-start);

    width: 100%;
    padding-right: 45px;
    flex: 1;
  }
  &__year-picker {
    margin-left: auto;
    width: 300px;
    padding-right: 30px;
    padding-top: 12px;
    max-height: 30px;
  }
}
