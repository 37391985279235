@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.empty-page {
  @include size(100%, 100vh);
  @include flex(column, center, center, 2rem);
  background-color: $background-primary;

  &__image {
    @include size(10rem);
  }

  &__description {
    text-align: center;
    h1 {
      @include fonts(56px, 600);
    }
    h2 {
      @include fonts(20px);
    }

    @include sm_breakpoint {
      h1 {
        @include fonts(46px, 600);
      }
      h2 {
        @include fonts(15px);
      }
    }
  }

  .hourglass {
    width: 50%;
    margin: auto;
    text-align: center;

    @keyframes top-clip {
      0% {
      }
      50% {
        transform: translateY(21px);
      }
      100% {
        transform: translateY(21px);
      }
    }

    @keyframes bottom-sand-path {
      0% {
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes bottom-sand-g {
      0% {
      }
      85% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-9px);
      }
    }

    @keyframes hourglass-rotation {
      50% {
        transform: rotateZ(0);
      }
      100% {
        transform: rotateZ(180deg);
      }
    }

    #top-sand #top-clip-path rect,
    #bottom-sand path,
    #bottom-sand g,
    svg {
      animation-duration: 5s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }

    #top-sand #top-clip-path rect {
      animation-name: top-clip;
    }

    #bottom-sand path {
      transform: translateY(21px);
      animation-name: bottom-sand-path;
    }

    #bottom-sand g {
      animation-name: bottom-sand-g;
    }

    svg {
      animation-name: hourglass-rotation;
    }
  }
}
