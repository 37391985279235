@import 'src/sass/colors.scss';
@import '../../sass/flex.module.scss';
@import '../../sass/size.module.scss';
@import '../../sass/fonts.module.scss';
@import '../../sass/breakpoints';

.nce-dropdown {
  &__content {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    direction: auto;
    width: 100%;
    padding: 6px 0px;
    border-radius: 8px;
    background-color: $color-white;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);

    > ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      > li {
        @include fonts(14px, 400, $line-height: 28px);
        @include flex(row, center, center);

        word-wrap: break-word;
        cursor: pointer;

        &:hover {
          background-color: $color-gray-f5;
          font-weight: 600;
        }
      }
    }

    &--open {
      visibility: visible;
      transform-origin: top center;
    }

    &--up {
      bottom: 100%;
      transform-origin: bottom center;
    }

    &--left {
      right: 0;
      transform-origin: top right;
    }
  }
}
