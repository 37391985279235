@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.edit-nwd-modal {
  .modal__container {
    @include flex(column, $justify-content: flex-start, $align-items: flex-start);
    @include size(630px, 550px);

    padding: 0;
  }
}
