@import "src/sass/colors.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";

.employees {
  &-table {
    &__deleted-user {
      opacity: 0.5;
    }

    &__loader {
      @include flex(row, center, center);

      height: 40px;
    }
  }
  &__table-container {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0.8rem; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-gray-f1; /* color of the tracking area */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-88; /* color of the scroll thumb */
      border-radius: 10px;
      border: 3px solid $color-gray-f1;
    }

    .nce-table {
      tbody {
        tr {
          background-color: $color-white;
          border-left: 3px solid transparent;
          &:hover {
            background-color: $color-gray-eb;
            cursor: pointer;
            border-left: 3px solid $royal-peacock;
            .nce-table__name {
              p {
                color: $color-black;
              }
            }
          }

          td {
            p {
              font-family: "Poppins";
            }
          }
        }
        .nce-table__role,
        .nce-table__phone {
          p {
            color: $color-gray-4f;
            @include fonts(14px);
          }
        }
      }
    }

    &__avatar {
      @include size(40px, 40px);
      object-fit: cover;
      border-radius: 80%;
      img {
        @include size(40px);
        border-radius: 162px;
      }
    }

    &__name {
      @include flex(row, flex-start, center, 1rem);
      @include fonts(16px, 500);
      color: $color-gray-30;
      text-decoration: none;
    }

    &__name-header {
      @include flex(row, flex-start, center, 1rem);
      button {
        width: 1.5rem;
        padding: 0;
      }
    }

    &__email {
      @include fonts(14px, normal);
      text-decoration: none;
    }
  }
}

.sort-icon--1 {
  svg {
    transform: scale(-1);
  }
}
