@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.snackbar {
  @include fonts(16px, bold);
  @include flex(row, space-between, center);
  flex-direction: row-reverse;

  position: fixed;
  padding: 12px 20px;
  background-color: red;
  color: $color-white;
  border-radius: 4px;
  z-index: 3000;

  &--error {
    background-color: red;
  }
  &--success {
    background-color: green;
  }

  &--top {
    top: 16px;
    animation: slide-down normal 1s;
  }
  &--bottom {
    bottom: 16px;
    animation: slide-up normal 1s;
  }

  &--left {
    left: 16px;
  }
  &--right {
    right: 16px;
  }

  &--center {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  svg {
    @include size(18px);

    margin-left: 20px;
    fill: $color-white;

    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes slide-down {
  from {
    top: 0;
    transform: translate3d(-50%, -100%, 0);
  }
  to {
    top: 16px;
  }
}

@keyframes slide-up {
  from {
    bottom: 0;
    transform: translate3d(-50%, 100%, 0);
  }
  to {
    bottom: 16px;
  }
}
