@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.document-dropzone {
  $b-root: &;

  @include flex(column);

  height: 10rem;

  &__dropzone {
    #{$b-root}__dropzone__wrapper {
      @include size(396px, 52px);

      margin-bottom: 0px;

      border: 2px dashed $color-gray-82;
      border-radius: 7px;
    }
  }

  &__error-message {
    @include fonts(12px);

    display: block;
    padding-left: 100px;

    color: $color-red;
  }

  &__file {
    @include flex(column, $align-items: center);
    @include size(396px, auto);

    @include xs-breakpoint {
      width: auto;
    }

    margin-top: 20px;
    align-self: center;

    border-radius: 7px;
    border: 1px dashed $color-gray-e0;
    background: $color-dropzone-background;
    box-shadow: 0px 4px 4px 0px $color-box-shadow;

    label {
      display: block;
      padding-left: 6px;
    }

    #{$b-root}__filename {
      @include flex($justify-content: center, $align-items: center);
      @include fonts(12px, $font-weight: 500);
      @include size(100%, 64px);

      background: $color-white;

      label {
        color: $color-gray-4f;
      }
      svg {
        @include size(12px, 12px);
      }
    }
    #{$b-root}__file-btns {
      @include flex($justify-content: space-between, $align-items: center);
      @include size(90%);

      padding: 10px 20px;

      button {
        height: 14px;
      }
    }

    #{$b-root}__remove-file-btn,
    #{$b-root}__edit-file-btn {
      @include flex($align-items: center);

      width: 100%;
      flex: 1;
      padding-left: 10px;

      color: $color-gray-4f;
      background-color: transparent;
      border: none;

      span {
        @include flex($align-items: center, $gap: 10px);
        width: 100%;
      }

      svg {
        @include size(12px);

        fill: $color-gray-4f;
      }

      &:hover {
        cursor: pointer;
        box-shadow: none;
        color: $color-gray-82;

        svg {
          fill: $color-gray-82;
        }
      }
    }

    #{$b-root}__remove-file-btn {
      span {
        justify-content: flex-end;
      }
    }
  }
}
