@import "src/sass/colors.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";

.tabs {
  @include flex(column, flex-start, normal, 30px);
  &__header {
    @include flex(row, flex-start, center, 24px);
    @include fonts(22px, 600);

    &__tab {
      position: relative;
      cursor: pointer;
      color: $color-gray-4f;
      padding-bottom: 8px;

      svg {
        fill: transparent;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $color-gray-26;
        transition: width 0.3s ease-in-out;
      }

      &--active::before {
        width: 100%;
        height: 3px;
      }

      &--active {
        transition: width 0.3s ease;
        color: $color-gray-26;
      }
    }
    @include xs-breakpoint {
      @include flex(column, $align-items: flex-start);
      @include fonts(18px);
    }
  }
}
