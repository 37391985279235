@import "src/sass/colors.scss";

@import "../../sass/flex.module.scss";
@import "../../sass/size.module.scss";
@import "../../sass/fonts.module.scss";

.dropzone-container {
  @include flex(column, center, center);

  $root: &;

  width: 100%;

  &__wrapper {
    @include size(200px, 100px);
    @include flex(column, center, center);
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 16px;

    width: 100%;

    p {
      padding-left: 12px;
      padding-right: 12px;
      text-align: center;
      color: $color-gray;
    }

    #{$root}__button {
      @include size(35px);
      @include fonts(14px, bold);
      @include flex(row, center, center);
      border-radius: 50%;

      border: none;
      background-color: $color-white;

      position: absolute;
      z-index: 2;
      bottom: 5px;
      left: 50%;

      &:hover {
        box-shadow: none;
      }

      transform: translate3d(-50%, 0, 0);
    }
  }
}
