@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';

.ne-page {
  @include flex(column);
  gap: 3rem;
  padding: 2rem 2rem;
  flex: 1;
  min-height: 100%;
  background-color: $color-gray-f2;
}
