@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.set-password {
  .auth-container--wrapper {
    height: 500px;
  }

  @include sm-breakpoint {
    .auth-container--wrapper {
      height: 700px;
    }
  }

  .auth-container__title {
    margin-bottom: 8px;
  }

  @include xs-breakpoint {
    .set-password__container {
      width: 17rem;
    }
  }

  &__container {
    @include flex(column, center, center);
    @include size(350px, fit-content);

    .ne-input {
      width: 100%;
    }

    &__input {
      margin-bottom: 5px;
      input {
        &:hover {
          box-shadow: none;
        }
      }

      &__disabled {
        input {
          color: rgba(128, 128, 128, 0.8);
          &:hover {
            box-shadow: none;
            cursor: not-allowed;
          }
        }
      }
    }
    .password-field {
      margin-top: 0;
      margin-bottom: 5px;
      width: 100%;
    }

    &__register-button {
      @include size(100%, 35px);
      @include fonts(13px, bold);

      border: none;
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}
