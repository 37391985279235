@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/flex.module.scss';

.table-title {
  @include flex();

  max-height: 30px;
  align-items: flex-end;

  svg {
    @include size(20px);

    margin-right: 16px;
    rect {
      fill: $color-gray-32;
    }
  }

  h2 {
    @include fonts(20px, 700, 1px, 20px);

    color: $color-gray-4f;
  }
}
