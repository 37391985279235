@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.menu {
  @include flex(column);
  @include size(100%);

  position: relative;
  flex: 1;

  img {
    @include size(20px, 20px);

    margin-right: 15px;
    display: flex;
    justify-content: center;
  }
  @include xs-breakpoint {
    width: auto;
    flex: 0;
  }
}
