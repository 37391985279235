@import "src/sass/flex.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.ne-section-block {
  padding: 2rem;
  background-color: $color-white;
  @include flex(column);
  gap: 2rem;
  position: relative;
  border-radius: 0.5rem;

  &__header {
    @include flex(row, space-between);
    flex-wrap: wrap;
    row-gap: 1rem;

    @include xs-breakpoint {
      gap: 1rem;
      h2 {
        font-size: 20px;
      }
    }
    h2 {
      @include flex(row, center, center, 0.1rem);
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: $background-secondary;
    opacity: 0.6;
    @include flex(row, center, center);

    pointer-events: none;
  }
}
