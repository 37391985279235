@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';

.page-container {
  display: flex;
}

.main-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3rem;
  animation: spin 1s linear infinite;
  opacity: 1;
}
