@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';

.search-bar {
  width: 100%;
  $root: &;
  #{$root}__icon-wrapper {
    position: relative;
    #{$root}__input {
      @include size(100%, 45px);
      @include fonts(14px);
      border-top: none;
      border-left: none;
      border-right: none;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding-left: 35px;
      text-indent: 9px;

      &:focus {
        outline: none;
        border-bottom: 2px solid $color-black;
      }
    }

    #{$root}__icon {
      @include size(24px, 24px);
      fill: $color-black;
      position: absolute;
      top: 13px;
      left: 5px;
      z-index: 10;
    }
  }
}
