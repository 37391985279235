@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.ne-avatar {
  img {
    object-fit: cover;
    border-radius: 100%;
  }
  &--xl {
    img {
      @include size(120px);
    }
  }
  &--sm {
    img {
      @include size(32px);
    }
  }
}
