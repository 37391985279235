@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';

.multiplier-users {
  display: grid;
  list-style-type: none;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  overflow: auto;
  max-height: 300px;

  li {
    @include flex(row, flex-start, center);
    gap: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0.3rem;

    img {
      box-shadow: none;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-gray-d9;
    }
  }
}

form {
  h1 {
    margin: auto;
  }

  button {
    &:hover {
      opacity: 80%;
    }
  }
}

.multiplier-users-scrollable {
  max-height: 17rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.8rem; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: $color-gray-f1; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-88; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 3px solid $color-gray-f1; /* creates padding around scroll thumb */
  }
}
