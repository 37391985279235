@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/colors.scss";

.employment-info {
  &__documents-section {
    margin-bottom: 1.5rem;
    .ne-section-block__header {
      h2 {
        p {
          @include flex(row, flex-start, center, 8px);
          button {
            padding: 0;
          }
        }
      }
    }

    &__accordion {
      &__open {
        &:hover {
          cursor: pointer;
        }
        button {
          svg {
            transform: rotate(270deg);
            transition-duration: 0.5s;
          }
        }
      }

      &__closed {
        &:hover {
          cursor: pointer;
        }
        button {
          svg {
            transform: rotate(180deg);
            transition-duration: 0.5s;
          }
        }
      }
    }
  }

  @include sm-breakpoint {
    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 6px 4px;
          }
        }
      }
    }

    &__section {
      h2 {
        font-size: 20px;
      }
    }

    &__add-button {
      font-size: 0px;
      gap: 0px;
    }
  }

  &__timeoffs-table {
    background-color: $color-white;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 32px;
    border-radius: 8px;

    h1 {
      @include flex(row, flex-start, center, 8px);
      font-size: 1.5rem;
      button {
        padding: 0;
      }
      svg {
        width: 24px;
      }
    }

    .ne-section-block {
      padding: 0;
    }

    &__accordion {
      &--open {
        padding-bottom: 32px;
        &:hover {
          cursor: pointer;
        }
        button {
          svg {
            transform: rotate(270deg);
            transition-duration: 0.5s;
          }
        }
      }
      &--closed {
        &:hover {
          cursor: pointer;
        }
        button {
          svg {
            transform: rotate(180deg);
            transition-duration: 0.5s;
          }
        }
      }
    }
  }
}
