@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/animation/fade-in.module.scss';

.change-password {
  @include flex(column, space-evenly, normal, 7px);

  @include fade-in(1.5s);

  border-radius: 0.5rem;
  background-color: $color-white;
  width: 33rem;

  @include lg-breakpoint {
    @include flex(column, center, center);
    width: 100%;
  }

  &__inputs {
    @include flex(column, center, center, 24px);

    @include lg-breakpoint {
      @include flex(column, center, normal);
    }

    margin-top: 3rem;

    .password-field {
      width: 250px;
      height: 4rem;

      label {
        font-weight: 700;
      }

      input:disabled {
        cursor: not-allowed;
      }
    }
  }

  Button {
    margin: 24px 24px 24px auto;
  }
}
