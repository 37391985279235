@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.non-working-days {
  @include flex(column, $gap: 20px);

  flex: 1;

  background-color: $color-gray-f2;

  &__header {
    @include flex($justify-content: space-between);
  }

  &__link {
    @include flex($align-items: center, $gap: 15px);
    @include fonts(16px, $font-weight: 600);

    color: $color-gray-4f;
    text-decoration: none;
  }

  &__add-button {
    svg {
      @include size(1rem);
    }

    &:hover {
      box-shadow: 0px 0px 10px 0px $color-gray;
    }
  }

  @include sm-breakpoint {
    padding-top: 5rem;
  }

  @include xs-breakpoint {
    &__add-button {
      font-size: 0px;
      gap: 0;
    }
  }
}
