@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.dropzone-active-content {
  @include size(100%);
  @include flex($justify-content: center, $align-items: center);

  border-radius: inherit;
  background-color: $color-gray-bd;
}
