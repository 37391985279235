@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.pending-admin-actions {
  @include flex($justify-content: center, $gap: 6px);
  padding: 8px;
  margin: 0px auto;
  width: fit-content;
  position: relative;
  border-radius: 6px;
  color: $color-gray-fb;
  font-weight: bold;
  background-color: $color-orange;

  &__pending-trigger {
    padding: 0;
    min-height: unset;

    &:hover {
      color: $color-gray-e6;
    }
  }
  &__buttons {
    @include flex(column);
  }

  .nce-dropdown__content {
    width: fit-content;
    height: fit-content;
    top: 30px;
    right: -10px;
    color: $color-black;
    text-wrap: nowrap;
    padding: 10px;

    li {
      min-width: 96px;
      button {
        font-size: small;
        min-height: unset;
        padding: 4px;
        svg {
          @include size(20px, 20px);
        }
      }
    }
  }
}
