@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.vacations-table {
  width: 95%;
  @include sm-breakpoint {
    width: 80%;
    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 0;
          }
        }
      }
    }
  }

  .nce-table {
    &__start-date {
      p {
        @include flex(row, center, center, 0.5rem);
        button {
          padding: 0;
          width: 1.5rem;
        }
      }
    }
  }
}
