@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.ne-daysoff {
  &__cards-container {
    @include flex(row, $gap: 20px);
    flex-wrap: wrap;

    @include sm-breakpoint {
      @include flex(column, $gap: 16px);
    }
  }

  svg {
    rect {
      fill: currentColor;
    }
  }

  @include sm-breakpoint {
    &__request-vacation-button {
      svg {
        visibility: hidden;
        width: 0px;
      }
    }
  }
}

.nce-table {
  &__download {
    @include flex(row, center, center);
  }
}

.ne-page {
  gap: 20px;
}
