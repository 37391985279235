@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.profile {
  background-color: $background-primary;
  padding: 20px;
  flex: 1;

  &__tabs {
    margin: 10px 50px 0 50px;

    &__change-pass,
    &__personal-info {
      @include xs-breakpoint {
        font-size: 15px;
        svg {
          @include size(1rem);
        }
      }
    }

    @include sm-breakpoint {
      margin: 0;
      padding-top: 3rem;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 50px $color-white inset;
  }
}
