@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/animation/fade-in.module.scss';
@import 'src/sass/colors.scss';

.password-recovery {
  $root: &;

  height: 100vh;

  &__container {
    @include size(100%);
    @include flex(column, center, center);
    @include fade-in(1.5s);
    background-color: $background-primary;

    #{$root}__wrapper {
      @include size(300px, 370px);
      @include fade-in(1.5s);

      padding: 50px 40px;
      position: relative;
      background-color: $color-white;
      border-radius: 10px;
      margin-bottom: 80px;
      #{$root}__header {
        margin-top: 50px;
        &__logo {
          @include size(65px);
          position: absolute;
          top: 25px;
          left: 50%;

          transform: translate3d(-50%, 0, 0);
        }
        &__title {
          @include fonts(25px, bold);
        }
      }
      #{$root}__text {
        @include fonts(14px);
        margin-top: 30px;
        color: $color-gray-4f;
      }
      #{$root}__form {
        @include flex(column, center, center);
        margin-top: 10px;

        #{$root}__email {
          width: 100%;
          input {
            width: 300px;
            margin-top: 15px;
          }
          small {
            font-size: 10px;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover {
            -webkit-box-shadow: 0 0 0 50px $color-gray-e6 inset;
          }
        }
        #{$root}__back-to-login {
          font-size: 13px;
          margin-top: 80px;
          margin-bottom: 6px;
          color: $color-gray-4f;
        }

        #{$root}__button {
          @include fonts(13px, bold);
          @include size(100%, 36px);

          border: none;
          border-radius: 5px;
        }
      }
    }
  }
}
