@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.exchange-rate-modal {
    $b-root: &;

    text-align: center;

    .modal__content {
        width: 580px;
        @include flex(column, $gap: 16px);
    }
    #{$b-root}__title {
        @include fonts(20px, 700, $line-height: 30px);
    }

    #{$b-root}__description {
        @include fonts(15px, 500, $line-height: 21px);
        color: $color-gray-4f;
    }

    &__form {
        @include flex(column, $gap: 24px);

        margin-top: 24px;
    }

    &__form-fields {
        @include flex(column, $gap: 36px);

        @include sm-breakpoint {
            flex-direction: row;
            justify-content: center;
        }
    }

    &__default-currency,
    &__secondary-currency {
        @include flex($gap: 18px, $align-items: center);
        flex: 1;

        @include sm-breakpoint {
            flex-direction: column;
            align-items: stretch;
        }

        label {
            @include fonts(14px, 700);
            flex: 1;
        }

        #{$b-root}__field,
        #{$b-root}__input-field {
            @include fonts(16px);

            flex: 2;
            box-sizing: border-box;
            padding: 6px;
            height: 48px;

            border: 1px solid $color-gray-4f;
            border-radius: 8px;
            color: $color-gray-4f;
        }

        #{$b-root}__field {
            @include flex($align-items: center, $justify-content: center);
        }

        #{$b-root}__input-field {
            position: relative;

            div {
                border: none;
            }

            input,
            select {
                text-align: center;
                color: $color-gray-4f;
            }

            select {
                border: none;
                &:focus,
                &:hover {
                    outline: none;
                }
            }

            small {
                margin: 0;
                position: absolute;
                bottom: 0;
                transform: translate(0, calc(100% + 6px));
            }
        }
    }

    &__btns-container {
        @include flex(row, space-between, center, $gap: 12px);
        flex-wrap: wrap;
        margin-top: 24px;

        button {
            @include fonts(14px, $font-weight: 500);
            min-width: 120px;
        }

        #{$b-root}__cancel-btn {
            margin-right: auto;
        }

        #{$b-root}__remove-currency-btn {
            color: $color-red;
            border-color: $color-red;
        }
    }
}
