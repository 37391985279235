@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/absoluteposition.module.scss';

.toggle {
  @include size(266px, 54px);
  @include fonts(16px, 600, normal, 24px);
  position: relative;
  border-radius: 24px;

  input {
    @include size(266px, 54px);
    appearance: none;
    border-radius: 26px;
    background-color: $color-gray-30;
  }

  input::before,
  input::after {
    @include fonts(16px, 500, normal, 24px);
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  input::before {
    content: attr(data-on);
    color: $color-gray-30;
    left: 40px;
  }

  input::after {
    content: attr(data-off);
    color: $color-white;
    right: 30px;
  }

  &__slider {
    @include size(148px, 54px);
    @include absolute-position($top: 0, $bottom: 0, $left: 0);
    background-color: $color-gray-d9;
    color: $color-black;
    border-radius: 24px;
  }

  input:checked + &__slider {
    transform: translateX(120px);
    transition: 0.9s;
    content: attr(data-on);
  }

  input:checked::before {
    color: $color-white;
    transition: color 0.9s;
  }

  input:checked::after {
    color: $color-gray-30;
    transition: color 0.9s;
  }

  input:not(:checked) + &__slider {
    transition: 0.9s;
  }
}
