@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.document-form {
  $b-root: &;

  @include flex(column);
  gap: 1rem;

  overflow: auto;

  &__dates {
    @include flex(row);
    gap: 2rem;
    > div {
      flex: 1;
    }
  }

  &__end-date {
    @include flex(column);
    gap: 1rem;
  }

  &__net-salary-field {
    @include flex(row);
  }

  &__salary {
    gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;

    .input-container {
      width: auto;
      &__input {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 48px;
        background-color: $color-gray-f2;
      }
    }

    #{$b-root}__salary-sufix {
      margin-right: 8px;
      font-size: 14px;
      color: $color-gray-33;
    }
  }
  &__doc,
  &__gross-salary {
    @include flex($gap: 8px);
    b {
      @include fonts(14px, 600);
      color: $color-gray-4f;
    }
  }

  textarea {
    border: 2px solid $color-gray-33;
    border-radius: 8px;
    padding: 4px;
  }
}
