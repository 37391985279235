@import 'src/sass/colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.link {
  &--button {
    @include size(100px, 30px);

    margin-top: 5px;
    font-weight: bold;
    border: none;
    text-decoration: none;

    @include flex(row, center, center);

    color: $color-black;

    &:hover {
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    }

    &--login {
      &--default {
        @include size(250px, 35px);
        @include fonts(00.833333333rem, bold);

        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        background-color: $color-gray-dd;
      }
    }

    &--register {
      &--default {
        @include size(250px, 35px);
        @include fonts(00.833333333rem, bold);

        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background-color: $color-gray-dd;
      }
    }

    &--profile-edit {
      @include fonts(1em);

      color: $color-white;
      font-family: 'Bebas Neue', sans-serif;
      margin-top: 30px;
      padding: 0px;
      background-color: $color-white;
      color: $color-black;
      border-radius: 25px;
    }
  }

  &--text {
    font-size: 13px;
    color: $color-black;
    font-weight: bold;
  }
}
