@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';

.overlay {
  @include size(100%);
  @include flex(row, center, center);
  position: absolute;
  background-color: $input-color-default;
  z-index: 5;

  &__loader {
    @include size(70px);
    animation: spin 1s linear infinite;
    opacity: 1;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
