@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.request-preview {
    height: 100%;

    form {
        @include flex(column);
        height: 100%;
    }

    &__description {
        @include fonts(16px);
        margin: 20px 0;
        text-align: center;
    }

    &__email-content {
        textarea {
            @include fonts(16px);
            border: 1px solid $color-gray-33;
            border-radius: 8px;
            padding: 0.5rem;
        }
    }

    &__btns-container {
        @include flex($justify-content: flex-end, $gap: 12px);
        flex-wrap: wrap;
        margin-top: auto;
        padding-top: 1rem;

        button {
            min-width: 150px;
            font-weight: 500;
        }
    }
}
