@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';

.ne-multiple-checkbox {
  @include flex(column, center, flex-start);

  &__label {
    @include fonts(14px, 600);
    color: $color-gray-4f;
  }

  &__content {
    @include flex(column, center, flex-start);

    width: -webkit-fill-available;
    padding: 1rem;
    box-shadow:
      0 3px 7px 0 rgba(0, 0, 0, 0.13),
      0 1px 2px 0 rgba(0, 0, 0, 0.11);

    &__select-all {
      align-self: flex-end;
      cursor: pointer;
    }

    &__selected-options {
      @include flex(row, flex-start, center);

      flex-wrap: wrap;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      width: 97.5%;
      max-height: 90px;
      gap: 0.5rem;
      padding: 10px 0 10px 10px;
      margin-bottom: 1rem;
      background-color: $background-secondary;

      @include sm-breakpoint {
        max-height: 4rem;
      }

      &--hidden {
        visibility: hidden;
      }

      &__option {
        @include flex(row, center, center);

        gap: 4px;
        border: 1px solid $color-black;
        border-radius: 10px;
        padding: 2px 6px;

        svg {
          @include size(16px);
          cursor: pointer;
        }
      }
    }

    .search-input {
      width: 100%;
      max-width: 100%;
      input {
        font-size: 14px;
      }
      padding-bottom: 0.5rem;
    }

    &__options {
      max-height: 150px;
      width: 100%;
      padding-left: 1rem;

      &__scroll {
        max-height: 150px;
        width: 98%;
        padding-left: 0.5rem;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0.8rem; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: $color-gray-f1; /* color of the tracking area */
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-gray-88; /* color of the scroll thumb */
          border-radius: 10px;
          border: 3px solid $color-gray-f1;
        }

        @include sm-breakpoint {
          max-height: 4rem;
        }
      }
    }

    &__spinner {
      align-self: center;
      width: 1rem;
      animation: spin 1s linear infinite;
      opacity: 1;
    }
  }
}
