@import 'src/sass/colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import 'src/sass/animation/fade-in.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/size.module.scss';

* {
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
}

.auth-container {
  @include flex(column, center, center);

  min-height: 100vh;
  width: 100%;

  background: $background-primary;

  &--wrapper {
    @include fade-in(1.5s);
    @include flex();
    @include size(750px, 460px);

    margin-bottom: 88px;
    border-radius: 10px;
    background-color: $color-white;
  }

  &__left {
    @include flex(column, center, center);

    width: 250px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $color-black;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: 50%;
      filter: invert(100%);
    }

    h1 {
      @include fonts(3em, 100);

      font-family: 'Bebas Neue', sans-serif;
      margin-top: 10px;
      color: $color-white;
    }
    h2 {
      @include fonts(2em, 100);

      font-family: 'Bebas Neue', sans-serif;
      margin-top: -15px;
      color: $color-white;
    }
  }

  &__right {
    margin: 32px auto;
    padding: 10px;
    min-width: 20rem;
  }

  &__label {
    @include fonts(1em);
  }

  &__form {
    @include flex(column);

    background-color: $color-white;
  }

  &__title {
    text-align: center;
    margin-bottom: 2rem;
  }
}

@include sm-breakpoint {
  .auth-container {
    width: 100vw;
    &--wrapper {
      @include flex(column);
      @include size(400px, fit-content);

      margin: 20px auto;
    }
    &__left {
      @include size(100%, 250px);
      padding-top: 1rem;
      border-radius: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      img {
        width: 30%;
      }
    }
    &__right {
      min-width: 10rem;
    }
  }
}

@include xs-breakpoint {
  .auth-container {
    width: 100vw;
    &--wrapper {
      @include flex(column);
      @include size(300px, fit-content);

      margin: 20px auto;
    }
    &__left {
      @include size(100%, 250px);

      border-radius: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      img {
        width: 30%;
      }
    }

    &__right {
      min-width: 10rem;

      .password-field,
      .input-container {
        width: 100%;
      }
    }
  }
}
