@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.nce-employee-multiplier-actions {
  width: 100%;
  @include flex(row, center, center);

  a {
    display: none;
  }

  .action-download {
    @include size(20px);
    fill: $color-gray-2f;
    cursor: pointer;
  }
  .action-edit {
    @include size(20px);
    fill: $color-gray-4f;
    cursor: pointer;
    &:hover {
      fill: $royal-peacock;
      transition: 0.2s;
    }
  }
  .action-remove {
    @include size(25px);
    fill: $color-red;
    cursor: pointer;
    &:hover {
      opacity: 50%;
    }
  }
}
