@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.multipliers-page {
  .multiplier-form-modal {
    form {
      @include flex(column);
      gap: 2rem;

      button {
        align-self: center;
      }
    }

    .modal__content {
      max-width: 480px;
    }
  }

  @include xs-breakpoint {
    max-height: 70vh;
    &__table-container {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 5rem;
            padding: 0;
          }
        }
      }
    }
  }

  &__search {
    max-width: 400px;
  }
}
