@import "src/sass/colors.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/fonts.module.scss";

.employee-request-vacation-modal {
  &__container {
    $root: &;

    @include flex(column, $align-items: center, $gap: 20px);
    @include size(630px, auto);

    height: 78vh;
    padding: 1rem;

    color: $color-gray-82;

    &__title {
      @include fonts(32px, 700, normal, 39px);
      color: $color-gray-26;
      margin-top: 12px;
      text-align: center;
    }

    &__notiffication {
      @include fonts(16px);
      text-align: justify;
      text-align-last: center;
      margin: 20px 0;
    }

    @include sm-breakpoint {
      width: auto;
    }
  }
}
