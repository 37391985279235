@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.remove-secondary-currency-modal {
    $b-root: &;

    &__container {
        @include flex(column, space-between, space-between, 0.5rem);

        align-self: center;
        height: 300px;
        max-width: 400px;
    }

    h3 {
        @include fonts(20px, $font-weight: 500);
        margin-top: 10px;
        color: $color-gray-26;
        text-align: center;
    }

    &__btn-container {
        @include flex($justify-content: space-between);

        #{$b-root}__cancel-btn,
        #{$b-root}__delete-btn {
            @include size(120px, 40px);
            @include fonts(14px, $font-weight: 500);

            border-radius: 8px;
        }

        #{$b-root}__delete-btn:hover {
            box-shadow: 0px 0px 10px 0px $color-gray;
        }
    }
}
