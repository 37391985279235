@import 'src/sass/colors.scss';

@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';

@keyframes up {
  from {
    bottom: -9.3rem;
  }
  to {
    bottom: 0rem;
  }
}

.login {
  @include flex(column);

  &__forgot-password {
    @include flex(row, center, center);
    margin: 32px 0 8px 0;
    gap: 0.25rem;
    &__text {
      @include fonts(13px);
      color: $color-gray-4f;
    }
    &__link {
      @include fonts(13px, bold);
      margin-left: 4px;
      color: $color-black;
    }
  }

  .password-field {
    min-width: 20rem;

    .ne-input__container {
      input {
        height: 2rem;
      }
    }
  }

  @include sm-breakpoint {
    .password-field {
      min-width: 15.5rem;
    }
  }

  &__email-field {
    .ne-input__container {
      input {
        height: 2rem;
      }
    }
  }

  button {
    border-radius: 4px;
  }
}
