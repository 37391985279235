@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';

.days-off-card {
  @include flex(column, $justify-content: space-between, $align-items: flex-end, $gap: 15px);
  @include size(294px, 110px);

  padding: 15px 18px;

  background-color: $color-white;
  border-radius: 8px;
  background: $color-white;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.03);

  &__title {
    @include flex(row, $align-items: flex-start, $gap: 10px);
    @include fonts(16px, $font-weight: 700, $line-height: 24px);

    align-self: flex-start;

    color: $color-gray-4f;

    span {
      width: 158px;
    }

    svg {
      @include size(40px, 40px);
    }
  }

  &__available-days {
    @include fonts(38px, $font-weight: 750, $line-height: 57px);

    color: $color-gray-33;
  }
}
