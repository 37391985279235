@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.salary-variables-card {
  align-self: baseline;

  width: 400px;

  .ne-section-block__aside {
    button {
      padding: 0;
    }
  }

  &__content {
    @include flex(column);
    gap: 1rem;
  }
  &__row {
    @include flex(row, $align-items: center);
    flex-wrap: wrap;
    gap: 1rem;
    b,
    p {
      flex-basis: 150px;
    }
  }

  &__button {
    svg:hover {
      fill: $royal-peacock;
      transition: 0.2s;
    }
  }

  @include sm-breakpoint {
    width: auto;
  }
}

.salary-icon {
  @include size(2.5rem);
}
