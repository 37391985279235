@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.document-modal {
  .modal__content {
    padding: 1rem;
  }
  form {
    @include flex(column);
    gap: 1.5rem;
    overflow: auto;
    width: 40rem;
    padding: 1rem;
    h2 {
      text-align: center;
    }

    @include sm-breakpoint {
      width: auto;
    }
  }

  &__actions {
    @include flex(row, center, center);
    gap: 1.5rem;
    > button {
      flex: 1;
      max-width: 260px;
      gap: 2rem;
    }
  }
}
