@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.dashboard {
  $b-root: &;
  &__number-of-employees {
    @include flex(column);
    @include size(330px, 133px);

    background-color: $color-white;
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.03);

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 10px $color-gray;
    }

    #{$b-root}__elements {
      display: flex;
      padding: 1em;
      gap: 0.5em;

      #{$b-root}__calendar {
        @include size(20px, 20px);
      }
      #{$b-root}__text {
        @include fonts(16px, 700, normal, normal);

        color: $color-gray-4f;
      }
    }

    #{$b-root}__employee-all {
      @include flex(row-reverse, space-between, baseline);

      padding: 1em;

      #{$b-root}__number-employee {
        @include fonts(38px, 700, normal, normal);
        @include flex(row, flex-start, flex-end);

        color: $color-gray-30;
        text-align: right;
        margin-left: 0.5em;
      }

      #{$b-root}__view {
        @include fonts(16px, 300, normal, normal);
        cursor: pointer;
        opacity: 0.8;
        color: $color-gray-4f;
        font-style: italic;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__add-employee-button {
    &:hover {
      box-shadow: 0 3px 10px $color-gray-82;
      background-color: $color-gray-32;
      transition-duration: 0.3s;
    }
  }

  &__icon {
    svg {
      color: $color-gray-4f;
      @include size(1.5rem);
    }
  }

  @include sm-breakpoint {
    padding-top: 80px;
    .ne-page-header {
      @include flex(column, flex-start, flex-start, 1rem);
    }
  }

  &__timeoffs {
    background-color: $color-white;
    border-radius: 8px;
    padding: 32px;
    div {
      @include sm-breakpoint {
        padding-top: 0;
      }
    }
    h1 {
      @include flex(row, flex-start, center, 8px);
      font-size: 1.5rem;
      padding: 0 0 48px 0px;
      @include sm-breakpoint {
        font-size: 1rem;
      }
    }
    .ne-timeoffs-table__data {
      padding: 0;
      gap: 16px;
    }
  }
}
