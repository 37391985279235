@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.update-salary-info-modal {
    &__content {
        @include flex(column, $gap: 20px);

        max-width: 500px;
        text-align: center;

        p {
            color: $color-gray-4f;
        }
    }

    &__salary {
        @include flex(column, $gap: 6px);
        color: $color-black;
    }

    &__salary-row {
        @include flex($gap: 6px, $justify-content: center);
    }

    &__btns {
        @include flex($gap: 20px);

        button {
            flex: 50px 1 0;
        }
    }
}
