@import 'src/sass/colors.scss';
@import '../../sass/breakpoints.module.scss';
@import '../../sass/flex.module.scss';
@import '../../sass/size.module.scss';

@keyframes down {
  from {
    bottom: 9.3rem;
  }
  to {
    bottom: 0px;
  }
}

@keyframes colorChange {
  from {
    background-color: $color-black;
    color: $color-white;
  }
  to {
    background-color: $color-gray-dd;
    color: $color-black;
  }
}

.register-container {
  &__buttons-container {
    @include flex(row, center);

    width: 360px;
    margin-top: 0;
    position: relative;
    animation: down 0.8s;
  }
}

.button {
  &--register {
    @include size(250px, 35px);

    margin-top: 5px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    background-color: $color-gray-dd;

    &--submit {
      background: $color-black;
      color: $color-white;
      border: none;

      animation: colorChange 0.5s;
      animation-direction: reverse;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

@include sm-breakpoint {
  @keyframes down2 {
    from {
      bottom: 5.7rem;
    }
    to {
      bottom: 0rem;
    }
  }
  .register-container {
    &__buttons-container {
      animation: down2 0.8s;
    }
  }
}
