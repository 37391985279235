@import "src/sass/flex.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/colors.scss";

.ne-general-vacations {
  &__title {
    @include flex(row, flex-start, center, $gap: 24px);
    flex-wrap: wrap;
  }

  .nce-table {
    &__days {
      p {
        padding-left: 0.5rem;
      }
    }
    &__edit {
      @include flex(row, center, center);

      &:hover {
        svg {
          fill: $royal-peacock;
          transition: 0.2s;
        }
      }
    }
    &__start {
      p {
        @include flex(row, flex-start, center);
      }
      svg {
        @include size(1.5rem);
      }
    }
  }

  @include sm-breakpoint {
    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 0;
          }
        }
      }
    }
  }

  @include xs-breakpoint {
    h2 {
      font-size: 18px;
    }

    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          th,
          td {
            min-width: 6rem;
            padding: 0;
          }
        }
      }
    }
  }

  .sort-icon--1 {
    svg {
      transform: scale(-1);
    }
  }
}
