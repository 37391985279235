@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';

@keyframes animate-pop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ne-personal-information {
  @include flex(row, space-between, $align-items: center, $gap: 2rem);

  &__section {
    gap: 0.5rem;
  }

  &__form {
    @include flex(column);
  }

  &__accordion {
    @include flex(row, center, center);

    svg {
      transform: rotate(180deg);
      transition-duration: 0.5s;
    }
    &--open {
      &:hover {
        cursor: pointer;
      }
      svg {
        transform: rotate(270deg);
        transition-duration: 0.5s;
      }
    }

    &--closed {
      &:hover {
        cursor: pointer;
      }

      svg {
        transform: rotate(180deg);
        transition-duration: 0.5s;
      }
    }
  }

  &__fields-wrapper {
    @include flex($align-items: flex-start, $gap: 16px);

    @include sm-breakpoint {
      @include flex(column, $gap: 16px);
    }
  }

  &__deactivated-message {
    @include fonts(16px, bold);
    text-align: center;

    margin-bottom: 20px;
    padding: 12px 20px;

    background-color: $color-red;
    color: $color-white;
    border-radius: 4px;
    opacity: 0.85;
  }

  &__tip {
    @include fonts(12px);
    color: $color-gray-4f;
    text-align: center;
    height: 14px;
    padding-bottom: 20px;
    padding-top: 6px;
    display: block;
    span {
      font-weight: 800;
      color: $color-gray-26;
    }
    div {
      animation-duration: 0.5s;
      animation-name: animate-pop;
      animation-timing-function: ease-in;
    }
  }

  &__img-wrapper {
    margin-top: 20px;
  }

  &__left {
    @include flex(column, flex-start, center);
    width: 200px;

    .image-upload-container {
      @include size(144px);
      flex-direction: row;
      margin: 0;

      &__image-wrapper {
        @include size(144px);
        margin: 0;

        img {
          @include size(144px);
        }
      }

      &__button {
        right: -16px;
      }

      .ncoded-employee-button {
        min-height: unset;
      }

      .dropzone-container__button {
        @include size(40px);

        bottom: 2px;
        padding: 8px 12px;

        @include sm-breakpoint {
          @include size(28px);

          bottom: -16px;
          padding: 4px 8px;
        }
      }
    }
  }

  &__right {
    display: grid;
    grid-template-columns: repeat(2, minmax(350px, 1fr));
    gap: 1rem 2rem;

    .ne-input {
      label,
      input {
        font-size: 16px;
      }
    }
    .ne-datepicker--disabled .react-datepicker__input-container {
      opacity: 1;
    }
  }

  &__link {
    @include flex($align-items: center, $gap: 15px);
    @include fonts(16px, $font-weight: 600);

    display: inline-flex;
    padding-bottom: 1rem;
    color: $color-gray-4f;
    text-decoration: none;
    width: fit-content;
  }

  &__invitation-status {
    @include flex(row, flex-end, flex-end);

    align-self: flex-end;
    width: 160px;

    .pending-invitation {
      @include fonts(16px, $line-height: 32px);
      @include flex(column, flex-end, flex-end);
    }

    .verified-icon {
      @include size(28px);

      fill: $royal-peacock;

      &__tooltip {
        background: $color-gray-88;
      }
    }
  }

  &__editing-actions {
    @include flex(row, flex-end, flex-end);
    width: 160px;
    align-self: flex-end;
  }

  &__actions-dropdown {
    .nce-dropdown__content {
      width: 200px;
    }
  }

  @include sm-breakpoint {
    @include flex(column);

    &__right {
      grid-template-columns: repeat(1, minmax(150px, 1fr));
    }

    &__left {
      @include flex(column, flex-start, flex-start);

      .image-upload-container {
        @include size(124px);

        margin-bottom: 24px;

        .image-upload-container__button {
          @include size(18px);

          right: -8px;
        }
      }

      .dropzone-container {
        &__wrapper {
          @include size(80px);

          margin: 0;

          > p {
            visibility: hidden;
          }
        }
      }
    }
  }
}
