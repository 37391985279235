@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.my-employees {
  @include size(100%, 100vh);
  background-color: $background-secondary;
  overflow-y: auto;
  padding-left: 1rem;
  $root: &;

  &__container {
    width: 100%;
    @include flex(column);

    #{$root}__header {
      padding: 2rem 1.5rem 0rem;
      @include sm-breakpoint {
        padding: 1rem 2rem 0rem 0.5rem;
      }
      @include flex(column);
      gap: 2rem;
      flex-wrap: wrap;

      #{$root}__main {
        @include flex(row, space-between, center);

        @include sm-breakpoint {
          @include flex(column);
          gap: 1rem;
        }
      }

      position: sticky;
      top: 0;
      z-index: 2;
      background-color: $color-gray-f2;

      flex-wrap: wrap;
      h1 {
        font-size: 32px;
        @include flex(row, center, center, 1rem);
        p {
          padding: 0.5rem;
          font-size: 16px;
          border-radius: 0.5rem;
          background-color: $color-white;
        }
      }

      #{$root}__add-button {
        height: 2.5rem;
        @include fonts(16px, 700);
        @include flex(row, center, center);
        align-self: center;

        svg {
          @include size(16px);
          fill: $color-white;
          margin-right: 15px;
        }
        span {
          margin-right: 10px;
        }

        @include sm-breakpoint {
          font-size: 13px;
        }

        @include xs-breakpoint {
          @include flex(column, center, center, 0.5rem);
          font-size: 12px;
          span {
            text-align: center;
            margin-right: 0px;
          }
          svg {
            margin-right: 0px;
          }
        }
      }

      #{$root}__add-button:hover {
        box-shadow: 0 3px 10px $color-gray-82;
        background-color: $color-gray-32;
        transition-duration: 0.3s;
      }
    }
    #{$root}__content {
      padding: 20px;

      @include sm-breakpoint {
        padding: 0.5rem;
        width: 90%;
      }

      #{$root}__no-users {
        &__images {
          @include flex(row, center, center);
          position: relative;
          margin-top: 150px;
          &__image-1,
          &__image-2,
          &__image-3 {
            @include size(120px);
            border-radius: 162px;
            box-shadow: 0px 4px 4px 0px $color-box-shadow;
          }

          &__image-1 {
            transform: translateX(calc(100% - 98px));
          }
          &__image-2 {
            z-index: 3;
          }
          &__image-3 {
            transform: translateX(calc(100% - 142px));
          }
        }
        &__description {
          @include fonts(16px, 400);
          color: $color-gray-82;
          line-height: normal;
          text-align: center;
          margin-top: 56px;
          width: 100%;
        }
      }

      #{$root}__empty-search {
        @include flex(column, center, center, 16px);
        @include size(100%);
        @include fonts(1.5rem, 600);
        margin-top: 100px;
        color: $color-gray-82;
        svg {
          @include size(64px);
          opacity: 60%;
        }
      }
    }
  }

  @include sm-breakpoint {
    padding-top: 3rem;
  }

  &__search {
    @include flex(row, space-between, center);
  }

  &__buttons {
    @include flex(row, flex-end, center, 0.5rem);
    &__button-inactive {
      opacity: 20%;
    }
    &__button-active {
      opacity: 100%;
    }
    button {
      padding: 0;
      width: 1.5rem;
      &:hover {
        opacity: 60%;
      }
    }
  }

  &__searchbar {
    height: 4rem;
    width: 90%;
    border: none;
    min-width: 10rem;
    @include sm-breakpoint {
      width: 80%;
    }
  }

  &__layout {
    background-color: $color-white;
    border-radius: 1rem;
    padding: 0rem 1rem 1rem;

    &__header {
      @include flex(row, space-between, center);
    }
  }
}
