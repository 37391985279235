@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.role-management {
    &__content {
        @include flex(column, $gap: 1rem);
    }

    &__add-admin-btn {
        align-self: flex-end;
    }
}
