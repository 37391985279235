@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.salary-variables-edit-modal {
  $b-root: &;

  h2 {
    margin: auto;
  }

  form {
    @include flex(column);
    gap: 2rem;
    overflow: auto;
  }

  &__fields-wrapper {
    @include flex(column, $gap: 18px);
  }

  &__actions {
    @include flex(row, center, center);
    margin-top: 2rem;
    gap: 1.5rem;
    > button {
      flex: 1;
      max-width: 260px;
      gap: 2rem;
    }
  }
}
