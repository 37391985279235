@import "src/sass/colors.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";

.ne-employee-request-vacation-form {
  @include flex(column, $gap: 2rem);
  @include size(100%, 100%);

  overflow: auto;

  &__fields-container {
    @include flex(column, $gap: 2rem);
    overflow: auto;

    background:
      linear-gradient($color-white 33%, rgba($color-gray-33, 0)),
      linear-gradient(rgba($color-gray-33, 0), $color-white 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba($color-gray-26, 0.5), rgba($color-black, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba($color-gray-26, 0.5), rgba($color-black, 0)) 0 100%;

    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size:
      100% 45px,
      100% 45px,
      100% 15px,
      100% 15px;
  }

  &__dates {
    @include flex(row);
    gap: 2rem;
    > div {
      flex: 1;
    }

    @include sm-breakpoint {
      flex-direction: column;
    }
  }

  .textarea-container {
    textarea {
      border: 1px solid $color-gray-33;
      border-radius: 8px;
      padding: 0.5rem;
    }
  }

  &__button {
    font-weight: 500;

    min-width: 150px;
    width: fit-content;
    margin-left: auto;
    margin-top: auto;
  }

  label {
    @include fonts(16px, 500, normal, 24px);
    color: $color-gray-26;
  }

  .ne-input__container {
    margin-top: 0.5rem;

    input {
      font-size: 16px;
    }
  }
}
