@import "src/sass/animation/fade-in.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/colors.scss";

@keyframes invalidInputAnimation {
  from {
    opacity: 0.25;
  }
  to {
    background-color: $color-gray-dd;
    opacity: 1;
  }
}

@keyframes validInputAnimation {
  from {
    background-color: rgb(4, 6, 4);
    opacity: 0.5;
  }
  to {
    background-color: $color-gray-dd;
    opacity: 1;
  }
}

.ncoded-small {
  font-size: 12px;
  &--invalid-input {
    color: $color-red;
    margin-top: 4px;
    font-weight: 500;
  }
}

.ne-input {
  @include flex(column, center);
  flex: 1;

  label {
    @include fonts(14px, 600);
    color: $color-gray-4f;
  }

  &__asterix {
    color: $color-red;
  }

  &__container {
    @include flex(row);

    &--filled {
      border-radius: 2px;
      padding: 4px 12px;
      background: $input-color-default;
    }
    &--underlined {
      padding: 0;
      border-bottom: 2px solid $color-gray-33;
    }
  }

  &__description {
    @include fonts(12px);
    padding-top: 0.5rem;
  }

  input {
    @include size(100%, 40px);
    @include fonts(14px);

    border: none;
    background-color: transparent;

    padding-block: 0;
    padding-inline: 0;

    &:focus {
      outline: none;
      border: none;
    }
  }

  &__suffix,
  &__preffix {
    @include flex(row, center, center);
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
    svg {
      cursor: default;
      pointer-events: none;
    }
  }
  &__span {
    color: $color-red;
  }
  &__notiffication {
    width: 508px;
    span {
      @include fonts(10px, 400, normal, 15px);
      color: $color-gray-4f;
    }
  }
  small {
    margin-top: 0.25rem;
  }
}
