@mixin fonts(
  $font-size: 1em,
  $font-weight: normal,
  $letter-spacing: normal,
  $line-height: normal,
  $font-family: 'Poppins'
) {
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  font-family: $font-family;
}
