@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/animation/fade-in.module.scss';

.personal-info {
  @include flex(row);
  border-radius: 0.5rem;
  background-color: $color-white;

  @include lg-breakpoint {
    @include flex(column, center, center);
    width: 100%;
  }
  @include fade-in(1.5s);

  &__image-div {
    @include flex(column, flex-start, center);

    margin: 32px;
    max-width: 300px;

    .dropzone-container__wrapper {
      @include size(230px);
      border-radius: 50%;
      margin-bottom: 0;

      .dropzone-container__button {
        @include size(3rem, 3rem);
        &:hover {
          box-shadow: 0px 0px 10px $color-gray;
        }
      }
    }

    .image-upload-container {
      position: relative;
      &__image-wrapper {
        @include size(240px);
        border: 0px;
        box-shadow: 0px 0px 10px $color-gray;
        img {
          @include size(240px);
        }
      }
    }

    @include xs-breakpoint {
      .dropzone-container__wrapper {
        @include size(130px);
        .dropzone-container__button {
          @include size(2.5rem, 2rem);
          padding: 0;
          svg {
            @include size(1rem, 1rem);
          }
        }
      }
      .image-upload-container {
        &__image-wrapper {
          @include size(140px);
          img {
            @include size(140px);
          }
        }
      }
    }

    p {
      @include md-breakpoint {
        display: none;
      }
      @include fonts(12px);
      color: $color-gray-4f;
      text-align: center;
      span {
        font-weight: 800;
        color: $color-gray-26;
      }
    }
  }

  &__form {
    @include flex(column);
    width: 100%;

    Button {
      margin: 24px 24px 24px auto;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;

    @include md-breakpoint {
      @include flex(column, center, center);
      .ne-input {
        min-height: 5rem;
      }
    }
    margin: 3.5rem 3rem 0 3rem;

    .ne-input,
    .ne-datepicker {
      justify-content: flex-start;

      &--disabled {
        input {
          pointer-events: none;
        }
      }

      height: 4rem;
      label {
        font-weight: 700;
      }

      input {
        &:hover,
        &:focus {
          border-bottom: 1.5px solid $color-black;
        }

        &:disabled {
          opacity: 0.3;
        }
      }

      @include lg-breakpoint {
        width: 100%;
      }
    }
  }
}
