@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';

.dropzone-content {
  @include size(100%);
  @include flex($justify-content: center, $align-items: center);
  @include fonts(14px, $font-weight: 400);

  border-radius: inherit;
  background-color: $color-dropzone-background;
  color: $color-gray-4f;

  &__label {
    color: $royal-peacock;

    &:hover {
      cursor: pointer;
    }
  }
}
