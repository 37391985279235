@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.error-page {
  @include size(100%, 100vh);
  @include flex(column, center, center, 2rem);
  background-color: $background-primary;

  &__image {
    @include size(10rem);
  }

  &__description {
    text-align: center;
    h1 {
      @include fonts(56px, 600);
    }
    h2 {
      @include fonts(20px);
    }

    @include sm-breakpoint {
      h1 {
        @include fonts(46px, 600);
      }
      h2 {
        @include fonts(15px);
      }
    }
  }
}
