@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/fonts.module.scss";
@import "src/sass/colors.scss";

.ne-personal-information__deactivate-modal {
    @include flex(column, flex-start, center);

    gap: 1.5rem;
    text-align: center;
    max-width: 600px;

    &__employee-info {
        @include flex(row, center, center);
        @include fonts(18px);
        gap: 1rem;

        .ne-avatar {
            @include size(40px);

            > img {
                @include size(40px);
            }
        }
    }

    &__actions-buttons {
        @include flex(row, flex-end, $gap: 12px);
        @include size(100%);

        margin-top: 20px;

        button {
            flex: 1;
        }
    }
}
