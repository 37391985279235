@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.menu-item {
  $root: &;

  &--logout {
    flex: 1;
    justify-content: end;
    padding: 15px 38px;
  }

  &:hover {
    background-color: $color-gray-eb;
  }

  &__element {
    @include flex(row, flex-start, center);

    padding: 15px 38px;
    transition: color 0.3s ease-in-out;
    gap: 16px;
    cursor: pointer;
    text-decoration: none;

    #{$root}__text {
      @include fonts(16px, 400, normal, normal);
      color: $color-gray-4f;
    }

    #{$root}__icon {
      @include size(20px);
      svg {
        stroke: transparent;
      }

      svg > g {
        > rect {
          fill: $color-gray-4f;
        }
        > circle {
          stroke: $color-gray-4f;
        }
        > path {
          stroke: $color-gray-4f;
        }
      }
    }

    &--active {
      gap: 16px;
      background-color: $color-gray-d9;

      #{$root}__icon {
        filter: brightness(0.5);
      }
      #{$root}__text {
        @include fonts(16px, 700, normal);
        color: $color-black;
      }
    }
  }
}
