@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.ne-general-vacation-form {
  @include flex(column);
  gap: 1rem;
  .textarea-container {
    textarea {
      border: 1px solid $color-gray-33;
      border-radius: 8px;
      padding: 0.25rem;
    }
  }
}

.general-vacation-form {
  &__remove-vacation {
    @include flex(row, flex-start, center);

    @include sm-breakpoint {
      text-wrap: nowrap;
      flex-wrap: wrap;
    }
  }

  &__remove-vacation-btn {
    background-color: transparent;
    color: $venetian-red;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.3rem;
    padding-right: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
      sans-serif;
  }
}
