@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/colors.scss';

.textarea-container {
  @include flex(column, flex-start, flex-start);

  textarea {
    box-sizing: border-box;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    border: none;
    border-bottom: 2px solid $color-gray-33;
    width: 100%;
    @include fonts(16px);
  }

  label {
    @include fonts(14px, 600);
    color: $color-gray-4f;
  }
}
