@import "src/sass/colors.scss";
@import "../../sass/flex.module.scss";
@import "../../sass/fonts.module.scss";
@import "../../sass/size.module.scss";

.image-upload-container {
  @include flex(column, center, center);

  margin-bottom: 16px;
  position: relative;

  &__wrapper {
    @include flex(column, center, center);

    margin-bottom: 20px;

    position: relative;
    width: 100%;
    border-radius: 50%;

    .dropzone-container--image-selected {
      position: relative;
      z-index: 10;
    }
  }

  &__image-wrapper {
    @include flex(row, center, flex-start);
    @include size(256px);
    border: 0px;

    position: absolute;

    cursor: default;

    margin-bottom: 16px;
    margin-top: 16px;
    border-radius: 50%;
    background-color: $color-white;
    img {
      @include size(256px);
      border-radius: 50%;
      object-fit: cover;
    }
    small {
      align-self: flex-start;
    }
  }

  &__dropzone {
    position: relative;
    button {
      visibility: visible;
    }
    &--image-selected {
      position: relative;

      button {
        visibility: visible;
      }
    }
  }

  &__button {
    @include size(30px);
    @include fonts(14px, bold);
    @include flex(row, center, center);

    z-index: 2;

    position: absolute;
    top: 0px;
    right: 0;
    padding: 0;

    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: $mountain-meadow;
    margin-left: 8px;

    svg {
      @include size(1.5rem);
      fill: $color-gray-66;
    }
  }

  &__modal {
    &__title {
      @include fonts(22px, bold);
      margin: 20px 0 30px;
      text-align: center;
    }

    &__buttons {
      @include flex(row, space-evenly, center);
      margin-bottom: 4px;
      @include size(100%, 45px);

      margin: 10px;
      border: none;
      border-radius: 8px;
      font-size: 20px;
      cursor: pointer;
      gap: 30px;

      &__button {
        @include size(100%, 45px);
        @include fonts(16px, 700);

        margin: 10px 10px 0;

        &--cancel {
          background-color: transparent;
          color: $color-gray-26;
          border: 1px solid $color-gray-26;
        }
      }
    }
  }

  &__errors {
    @include flex($justify-content: center, $align-items: center);
    background-color: rgba($color: $color-red, $alpha: 0.5);
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: darken($color: $color-red, $amount: 20);

    small {
      align-self: unset;
      text-align: center;
    }
  }
}
