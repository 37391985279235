@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
.password-field {
  @include flex(row, center, normal);
  margin: 16px 0px;

  position: relative;
  width: fit-content;

  &__icon {
    @include size(20px);

    &:hover {
      cursor: pointer;
    }
  }
}
