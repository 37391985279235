@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.progress-circle {
  &__label {
    color: $color-black;
    text-anchor: middle;
    @include fonts(20px, 500, $font-family: 'Poppins');
  }

  &__progress {
    transition: stroke-dashoffset 1s ease-out;
  }
}
