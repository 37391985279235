@import 'src/sass/colors.scss';

@import '../../sass/flex.module.scss';
@import '../../sass/fonts.module.scss';
@import '../../sass/size.module.scss';

.file-upload-container {
  @include flex(column, center, center);

  $root: &;

  &__wrapper {
    @include flex(column, center, center);
    width: 100%;

    #{$root}__file-preview {
      @include flex(row, space-between, center);
      @include fonts(14px, bold);

      position: relative;

      background: $mountain-meadow;
      color: $color-white;

      width: 300px;
      padding: 8px;
      border-radius: 8px;
      margin-top: 8px;

      svg {
        position: relative;
        bottom: 4px;

        filter: brightness(100%);
        filter: invert(1);
      }
    }
  }

  &__button {
    @include size(20px);

    border-radius: 50%;
    border: none;
    background-color: $color-gray-dd;

    color: $color-white;
    background-color: transparent;
    font-weight: bold;
    margin-left: 8px;

    &:hover {
      background-color: $color-white;
      color: $mountain-meadow;
      box-shadow: none;
    }
  }
  small {
    align-self: flex-start;
  }
}
