@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/colors.scss';

.currency-settings{

    &__description{
    color:$color-gray-4f;
    

}
}