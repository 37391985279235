@import 'src/sass/fonts.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';

.checkbox {
  display: flex;

  @include fonts(14px, 400, '', 21px);

  display: flex;
  transition: color var(--time) ease-in-out;
  margin-bottom: 1px;
  cursor: pointer;
  font-style: normal;
  // margin-left: 1em;
  position: relative;
  align-items: center;
  gap: 0.8em;

  &__box {
    @include flex(row, center, center);
    @include size(16px);

    flex: 0 0 auto;
    border: 1px solid $color-black;
    border-radius: 4px;
    transition: background 150ms ease-in-out;
    background-color: $color-gray-f2;
  }
  input:checked + &__box {
    position: relative;
    background-color: $color-gray;
    &:after {
      @include size(20%, 0);
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      padding-top: 50%;
      content: '';
      border-bottom: solid 2px $color-white;
      border-right: solid 2px $color-white;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}
