@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/fonts.module.scss';

.ncoded-employee-button {
  @include flex(row, center, center);
  min-height: 2.5rem;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  border: none;
  border-radius: 6px;

  @include fonts(1rem, 600);
  background-color: $color-black;
  color: $color-white;

  &--disabled {
    cursor: not-allowed;
    box-shadow: none;
    background-color: $background-primary;
    &:hover {
      cursor: not-allowed;
      svg {
        cursor: not-allowed;
      }
    }
  }

  &--outline {
    border: 1px solid $color-black;
    background-color: transparent;
    color: $color-black;
  }

  &--icon {
    background-color: transparent;
  }

  &--text {
    color: $color-gray-82;
    background-color: transparent;
  }
}
