@import 'src/sass/flex.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/size.module.scss';

.invitation-error {
  @include size(100vw, 100vh);
  @include flex(column, center, center);

  label {
    font-family: 'Bebas Neue', sans-serif;
  }

  &__404 {
    @include fonts(150px, 700);
  }
  &__description {
    @include fonts(36px, 400);
  }
  &__contact {
    @include fonts(30px, 400);
  }
}
