@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';

.multiselect {
  $b-root: &;

  position: relative;

  &__value {
    width: 100%;
    min-height: 40px;
    max-height: 110px;
    overflow-y: auto;
    background-color: $color-white;

    border: 1px solid $color-black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid $color-gray;

    #{$b-root}__open-btn {
      background-color: transparent;
      border: none;
      box-shadow: none;

      float: right;

      width: 30px;
      margin-top: 8px;
      margin-right: 10px;

      svg {
        @include size(16px);

        transform: rotate(270deg);
        stroke: $color-gray-4f;
        stroke-width: 1.3;
      }
    }
    #{$b-root}__selected-option {
      color: $color-black;
      border: 0.5px solid $color-black;
      background: $mountain-meadow;

      display: inline-block;
      width: fit-content;
      height: 26px;
      border-radius: 5px;
      margin: 4px;

      span {
        @include flex($align-items: center, $gap: 6px);
        @include fonts(16px, $font-weight: 400);

        border-radius: 5px;
        background: $color-white;
        color: $color-gray-4f;

        padding-right: 6px;
        height: 100%;
        margin-left: 5px;

        #{$b-root}__unselect-btn {
          display: contents;

          &:hover {
            cursor: pointer;
          }
        }

        svg {
          @include size(16px);

          fill: $color-gray-4f;

          &:hover {
            fill: $color-black;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      background-color: $color-gray-dd;
      border-top-right-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $color-gray-aa;
    }
  }

  &__title {
    @include fonts(16px, $font-weight: 600);

    padding-left: 14px;
    color: $color-gray-4f;
  }

  &__options-list {
    position: absolute;
    z-index: 1000;

    background-color: $color-white;

    list-style-type: none;
    width: 100%;
    max-height: 152px;
    overflow-y: auto;

    border: 1px solid $color-black;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;

    scroll-snap-type: both mandatory;

    #{$b-root}__option {
      @include flex($align-items: center);
      @include fonts(16px, $font-weight: 400);

      color: $color-gray-4f;

      padding-left: 14px;
      height: 32px;

      scroll-snap-align: center;
    }

    #{$b-root}__select-all {
      padding-left: 50px;
    }

    li[aria-selected='true'] {
      background-color: $mountain-meadow;
    }

    li:hover {
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      background-color: $color-gray-dd;
      border-bottom-right-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $color-gray-aa;
    }

    &:focus {
      outline: none;
    }
  }

  &__placeholder {
    display: block;
    margin-top: 8px;
  }

  &__error-msg {
    @include fonts(12px, $font-weight: 400);

    color: $color-red;
    padding-left: 5px;
  }

  button {
    &:focus-visible {
      outline: none;
    }
  }

  &--focused.multiselect--closed {
    #{$b-root}__value {
      border: 2px solid $color-black;
    }
  }

  &--overflow {
    #{$b-root}__options-list {
      top: 0;
      transform: translateY(-99%);

      border: 1px solid $color-black;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::-webkit-scrollbar {
        border-top-right-radius: 8px;
      }
    }

    #{$b-root}__value {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      svg {
        transform: rotate(90deg);
      }

      &::-webkit-scrollbar {
        border-bottom-right-radius: 8px;
      }
    }
  }

  &--closed {
    #{$b-root}__value {
      border-radius: 8px;
      border: none;
      background-color: $background-secondary;
      box-sizing: border-box;
    }
  }
}
