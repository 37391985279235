@import 'src/sass/size.module.scss';

.general-vacation-modal {
  .modal__content {
    @include size(530px, auto);
    gap: 2rem;
  }

  h2 {
    align-self: center;
  }
}
