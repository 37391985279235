@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';

.nce-employees-layout {
  @include size(100%);

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-row-gap: 24px;

    @include sm-breakpoint {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    @include xs-breakpoint {
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      grid-row-gap: 12px;
    }
  }
}
